import React from 'react';
import { Container } from 'react-bootstrap';
import { Switch, Route } from 'react-router';
import Login from '../components/auth/Login';
import Accounts from '../components/accounts/Accounts';
import PrivateRoute from './privateRouter';

const MainRouter = () => (
  <Container className="main-content">
    <Switch>
      <Route exact path="/" component={tempHome} />
      <Route path="/login" component={Login} />
      <Route path="/signup" component={tempSignUp} />
      <PrivateRoute path="/admin" component={tempAdmin} />
      <PrivateRoute path="/accounts" component={Accounts} />
    </Switch>
  </Container>
);
const tempHome = () => <h2>Home</h2>;
const tempSignUp = () => <h2>Signup</h2>;
const tempAdmin = () => <h2>Admin</h2>;

export default MainRouter;
