import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/shared/Header';
import MainRouter from './navigation/router';
import { AppContext } from './contexts/contextLib';
import { Auth } from 'aws-amplify';
import './App.css';

function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    } catch (e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }

    setIsAuthenticating(false);
  }

  return (
    !isAuthenticating && (
      <Container fluid>
        <Router>
          <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
            <Header />
            <MainRouter />
          </AppContext.Provider>
        </Router>
      </Container>
    )
  );
}

export default App;
