import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useAppContext } from '../../contexts/contextLib';

export default function Header() {
  const { isAuthenticated, userHasAuthenticated } = useAppContext();
  const history = useHistory();

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    history.push('/login');
  }

  return (
    <Navbar bg="light" variant="light">
      <Navbar.Brand href="#home">Accounts</Navbar.Brand>
      <Nav className="ml-auto" navbar pills="true">
        {isAuthenticated ? (
          <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
        ) : (
          <>
            <Nav.Link href="/login">Login</Nav.Link>
            <Nav.Link href="/signup">Signup</Nav.Link>
          </>
        )}
      </Nav>
    </Navbar>
  );
}
