import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import * as serviceWorker from './serviceWorker';
import { Amplify } from 'aws-amplify';

const {
  REACT_APP_AWS_REGION,
  REACT_APP_COGNITO_USER_POOL_ID,
  REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
  REACT_APP_COGNITO_IDENTITY_POOL_ID,
} = process.env;

ReactDOM.render(
  //<React.StrictMode>
  <App />,
  //</React.StrictMode>
  document.getElementById('root')
);

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: REACT_APP_AWS_REGION,
    userPoolId: REACT_APP_COGNITO_USER_POOL_ID,
    identityPoolId: REACT_APP_COGNITO_IDENTITY_POOL_ID,
    userPoolWebClientId: REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
  },
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
