import React, { useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import LoaderButton from '../shared/LoaderButton';
import { Auth } from 'aws-amplify';
import { useAppContext } from '../../contexts/contextLib';

import './Login.css';

export default function Login(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { isAuthenticated, userHasAuthenticated } = useAppContext();
  const history = useHistory();

  const referer = props.location.state ? props.location.state.referer : '/';

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    try {
      await Auth.signIn(email, password);
      userHasAuthenticated(true);
      history.push(referer);
    } catch (e) {
      alert(e.message);
      setIsLoading(false);
    }
  }

  if (isAuthenticated) {
    return <Redirect to={referer} />;
  }

  return (
    <div className="Login">
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="email" size="lg">
          <FormLabel>Email</FormLabel>
          <FormControl
            autoFocus
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </FormGroup>
        <FormGroup controlId="password" size="lg">
          <FormLabel>Password</FormLabel>
          <FormControl
            value={password}
            onChange={e => setPassword(e.target.value)}
            type="password"
          />
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          size="lg"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Login
        </LoaderButton>
      </form>
    </div>
  );
}
